body {
  margin: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
}

.main {
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: normal;
  min-width: 920px;
  padding: 20px 0 20px 0;
  width: 80%;
}

.titleBoxRow {
  display: flex;
  flex-direction: row;
  justify-content: left;
  position: relative;
  width: 100%;
  border-bottom: 1px solid grey;
}

.title {
  text-align: left;
  font-weight: normal;
  margin: 15px 0px 15px 10px;
  min-width: 520px;
}

.titleButtonContainer {
  position: absolute;
  right: 0;
}

.tutorialButton {
  background-color: rgb(60, 140, 190);
  border: 1px solid rgb(60, 140, 190);
  height: 40px;
  width: 90px;
  /* border: none; */
  margin: 15px 10px;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0;
}

.tutorialButton:hover {
  background-color: rgb(130, 180, 208);
  border: 1px solid rgb(130, 180, 208);
  color: white;
}

.section {
  min-width: 600px;
  display: flex;
  width: 50%;
  position: relative;
  justify-content: center;
  padding: 0 100px;
}

.sectionTitleBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: normal;
  min-width: 600px;
  padding: 20px 0 20px 0;
  width: 50%;
}

.footer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: gray;
}

.link {
  margin: 15px;
  color: grey;
}

.copyright {
  color: grey;
  font-size: 16px;
}

.sizeCheckblurBackground {
  display: none;
}

/* iphone max height */
@media screen and (max-height: 450px) {
  body {
    margin: 0;
    width: 100vw;
    height: auto;
    background-color: white;
  }

  .titleBox {
    visibility: hidden;
  }

  .copyright {
    visibility: hidden;
  }

  .link {
    visibility: hidden;
  }

  .sizeCheckblurBackground {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(60, 140, 190);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    backdrop-filter: blur(10px);
    color: white;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  .shearAndMomentDiagramBox {
    visibility: hidden;
  }
}

@media screen and (max-width: 500px) {
  body {
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
    background-color: white;
  }

  .main {
    margin: 0;
    padding: 0;
    display: flex;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .titleBox {
    display: none;
  }

  .copyright {
    visibility: hidden;
    display: none;
  }

  .link {
    visibility: hidden;
    display: none;
  }

  /* .sizeCheckblurBackground {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(60, 140, 190, 0.5); 
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 10;
    backdrop-filter: blur(10px);
    color: white;
    flex-direction: column;
    margin: 0;
    padding: 0;
  } */

  .shearAndMomentDiagramBox {
    visibility: hidden;
  }
}
