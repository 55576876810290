.diagramArea {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  border: 2px solid rgb(200, 200, 200);
  min-width: 300px;
}

canvas {
  position: absolute;
}

@media screen and (max-height: 450px) {
}

@media screen and (max-width: 500px) {
  .diagramArea {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    width: 100vw;
    border: none;
    min-width: 0;
    position: absolute;
    top: 40px;
  }
}
