.toolbar {
  border-radius: 5px;
  background-color: rgb(60, 140, 190);
  z-index: 1;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  position: absolute;
  margin-right: 10px;
  top: 10%;
  right: 0;
  bottom: 10%;
  width: 55px;
}

.toolbarTop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toolbarButtom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  font-size: 25px;
  color: white;
  padding: 0 10px;
  cursor: pointer;
}

.icon:hover {
  color: white;
}

.iconBox {
  border: none;
  background-color: inherit;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: 0.2s ease;
  margin: 0px 0px;
}

.iconBox:hover {
  background-color: rgb(130, 180, 208);
  cursor: pointer;
}

@media screen and (max-height: 450px) {
}

@media screen and (max-width: 500px) {
  .toolbar {
    border-radius: 0;
    margin: 0;
    padding: 0;
    background-color: rgb(64, 64, 64);
    z-index: 1;
    flex-direction: row;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0px;
    left: auto;
    right: auto;
    height: 40px;
    width: 100vw;
    min-width: 0;
  }

  .toolbarTop {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .toolbarButtom {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    font-size: 20px;
    color: white;
    padding: 0 0px;
    cursor: pointer;
  }

  .icon:hover {
    color: white;
  }

  .iconBox {
    border: none;
    background-color: inherit;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.2s ease;
    margin: 0px 3px;
    padding: 0 0px;
  }

  .iconBox:hover {
    /* background-color: rgb(90, 110, 138); */
    background-color: rgb(130, 180, 208);
    cursor: pointer;
  }
}
