.contactFormGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 100px auto;
  row-gap: 20px;
  /* align-items: center; */
  margin: 0;
  padding: 0;
}

.contactFormBody {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

.contactInputBox {
  width: 90%;
  height: 20px;
  /* font-size: 15px;
    padding: 3px 0 3px 3px; */
  margin: 0 0 0 0;
}

.contactInputArea {
  width: 90%;
  height: 250px;
  /* font-size: 15px;
    padding: 3px 0 3px 3px; */
  margin: 0 0 0 0;
  resize: none;
}

.contactForm {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.contactButtonContainer {
  display: flex;
  align-items: center;
}

.termsAgreementRow {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.termLink {
  color: grey;
  cursor: pointer;
}

.termLink:hover {
  color: blueviolet;
  text-decoration: underline;
}

.termCheckBox {
  margin: 0 10px;
}

.formTitle {
  padding: 20px;
  font-size: 20px;
}

.formButton {
  background-color: rgb(60, 140, 190);
  border: none;
  height: 40px;
  width: 80px;
  margin: 20px;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0;
}

.formButton:hover {
  background-color: rgb(130, 180, 208);
}

.formInputLabel {
  text-align: right;
  font-size: 16px;
  margin-right: 20px;
}

.formInputboxContainer {
  position: relative;
}
