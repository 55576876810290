.elementListPanel {
  border-top: 4px solid rgb(100, 100, 100);
  /* position: absolute; */
  overflow: auto;
  top: 0px;
  bottom: 0;
  z-index: 3;
  background-color: rgb(64, 64, 64);
  min-width: 320px;
  box-shadow: 5px 0 5px rgba(0, 0, 0, 0.4);

  /* newcode */
  position: relative;
  height: 100%;
  width: 320px;
}

.elementBox {
  display: flex;
  flex-direction: column;
}

.elementListInputError {
  color: rgb(255, 130, 130);
  border: 2px solid rgb(255, 130, 130);
  padding: 5px 20px;
}

.elementListInputError:empty {
  display: none;
}

.element {
  position: relative;
  background-color: rgb(64, 64, 64);
  display: flex;
  border-bottom: 4px solid rgb(100, 100, 100);
  color: white;
  height: 136px;
  width: 100%;
  z-index: 20;
}

.element:hover {
  background-color: rgb(89, 89, 89);
}

.elementIconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
}

.deleteElementIcon {
  position: absolute;
  right: 0;
  top: 6px;
  font-size: 20px;
  color: rgb(192, 192, 192);
  padding: 0 10px;
}

.deleteElementIcon:hover {
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.elementIcon {
  height: 30px;
  width: 30px;
  object-fit: contain;
}

.elementInput {
  display: flex;
  align-items: center;
}

.elementInputBox {
  border: none;
  color: inherit;
  background-color: rgb(107, 107, 107);
  padding: 3px 10px;
  margin: 3px 0;
}

.elementInputGrid {
  padding: 15px;
  padding-left: 10px;
  width: 180px;
  display: grid;
  grid-template-columns: 15px 30px 100px auto;
  align-items: center;
  /* background-color: gray; */
}

.unit {
  padding: 0 10px;
}

.elementInputLabel {
  width: 15px;
  text-align: right;
}

.equal {
  padding: 0 10px;
}

@media screen and (max-height: 450px) {
}

@media screen and (max-width: 500px) {
  .elementListPanel {
    border-top: 2px solid rgb(100, 100, 100);
    overflow: auto;
    top: calc(302px + 40px);
    bottom: 0;
    z-index: 3;
    background-color: rgb(64, 64, 64);
    min-width: 0;
    box-shadow: none;
    position: absolute;
    height: calc(100vh - 40px - 40px - 305px);
    width: 100vw;
  }

  .elementBox {
    display: flex;
    flex-direction: column;
  }

  .elementListInputError {
    color: rgb(255, 130, 130);
    border: 2px solid rgb(255, 130, 130);
    padding: 5px 20px;
  }

  .elementListInputError:empty {
    display: none;
  }

  .element {
    position: relative;
    background-color: rgb(64, 64, 64);
    display: flex;
    border-bottom: 2px solid rgb(100, 100, 100);
    color: white;
    height: 106px;
    width: 100%;
    z-index: 20;
  }

  .element:hover {
    background-color: rgb(89, 89, 89);
  }

  .elementIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
  }

  .deleteElementIcon {
    position: absolute;
    right: 0;
    top: 6px;
    font-size: 20px;
    color: rgb(192, 192, 192);
    padding: 0 10px;
  }

  .deleteElementIcon:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .elementIcon {
    height: 30px;
    width: 30px;
    object-fit: contain;
  }

  .elementInput {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  .elementInputBox {
    border: none;
    color: inherit;
    background-color: rgb(107, 107, 107);
    padding: 2px 10px;
    margin: 2px 0;
    font-size: 12px;
  }

  .elementInputGrid {
    padding: 15px;
    padding-left: 10px;
    width: 180px;
    display: grid;
    grid-template-columns: 15px 30px 150px auto;
    align-items: center;
  }

  .unit {
    padding: 0 10px;
    font-size: 12px;
  }

  .elementInputLabel {
    width: 15px;
    text-align: right;
    font-size: 12px;
  }

  .equal {
    padding: 0 10px;
    font-size: 12px;
  }
}
