.header {
  background-color: rgb(60, 140, 190);
  height: 50px;
  width: 100vw;
  /* padding: 0 10px; */
  z-index: 10;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(200, 200, 200);
  min-width: 920px;
}

.headerContainer {
  min-width: 920px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 100%;
}

.headerBoxRight {
  height: 100%;
  display: flex;
}

.headerBoxLeft {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
}

.headerBoxLeftContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerLogoName {
  padding: 0 10px;
  color: white;
  pointer-events: none;
}

.headerOption {
  font-size: 18px;
  color: white;
  padding: 0 10px;
  font-weight: 500;
  width: 75px;
  text-align: center;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerOption:hover {
  color: white;
  background-color: rgb(130, 180, 208);
}

.logo {
  height: 40px;
  width: 40px;
}

.languageOptionMenu {
  position: absolute;
  top: 50px;
  background-color: white;
  width: 75px;
  z-index: 200;
}

.languageOptionMenuInactive {
  display: none;
  z-index: 200;
}

.languageOption {
  height: 40px;
  border: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 95px;
}

.languageOption:hover {
  color: white;
  background-color: rgb(200, 200, 200);
}

.languageBlurBackground {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  backdrop-filter: blur(10px);
}

@media screen and (max-height: 450px) {
  .header {
    visibility: hidden;
  }
}

@media screen and (max-width: 500px) {
  .header {
    background-color: rgb(60, 140, 190);
    height: 40px;
    width: 100%;
    z-index: 10;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgb(200, 200, 200);
    min-width: 0;
  }

  .headerContainer {
    min-width: 375px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: rgb(60, 140, 190);
  }

  .headerBoxRight {
    height: 100%;
    display: flex;
  }

  .headerBoxLeft {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    padding: 0 10px;
  }

  .headerBoxLeftContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  .headerLogoName {
    padding: 0 5px;
    color: white;
    pointer-events: none;
  }

  .logo {
    height: 25px;
    width: 25px;
  }

  .headerOption {
    font-size: 14px;
    color: white;
    padding: 0 10px;
    font-weight: 500;
    width: 45px;
    text-align: center;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
  }
}
