.console {
  background-color: white;
  min-width: 920px;
  display: flex;
  width: 80%;
  height: 700px;
  position: relative;
}

@media screen and (max-height: 450px) {
  .console {
    visibility: hidden;
  }
}

@media screen and (max-width: 500px) {
  .console {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
    position: relative;
    min-width: 0;
    border: none;
    margin: 0;
    padding: 0;
  }
}
