.shearAndMomentDiagramBox {
  z-index: 100;
  border-radius: 5px;
  background-color: rgb(240, 240, 240);
  /* height: 850px; */
  height: 85vh;
  width: 900px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 30px;
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.4);
  overflow-y: hidden;
  overflow-x: hidden;
}

.shearAndMomentCanvasContainer {
  width: calc(100% - 30px);
  height: calc(100vh * 0.85 - 40px);
  position: fixed;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.shearAndMomentDiagramBoxTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 30px;
  padding: 20px 30px 0 0px;
  font-weight: bold;
  color: black;
}

.shearAndMomentDiagramFooter {
  position: relative;
  top: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 16px;
  padding: 0px 0px 20px 0px;
  font-weight: bold;
  color: rgb(200, 200, 200);
}
.shearAndMomentDiagramExport {
  position: absolute;
  right: 10px;
  top: 80px;
}

.exportIcon {
  font-size: 30px;
  color: rgb(60, 140, 190);
  padding: 0 10px;
  cursor: pointer;
}

.dataPoint {
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: red;
  z-index: 150;
  position: absolute;
}

.dataPoint .tooltiptext {
  visibility: hidden;
  white-space: nowrap;
  margin: 0;
  font-size: 12px;
  color: black;
  text-align: center;
  padding: 0px 2px;
  padding-bottom: 4px;
  position: absolute;
  left: 18px;
  top: 4px;
  z-index: 40;
}

.dataPoint:hover .tooltiptext {
  visibility: visible;
}

@media screen and (max-height: 450px) {
}

@media screen and (max-width: 500px) {
  .shearAndMomentDiagramBox {
    z-index: 100;
    border-radius: 5px;
    background-color: rgb(240, 240, 240);
    /* height: 850px; */
    height: 85vh;
    width: 85vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 5vw;
    box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.4);
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .shearAndMomentCanvasContainer {
    width: 90vw;
    height: calc(100vh * 0.85 - 40px);
    position: fixed;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .shearAndMomentDiagramBoxTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    font-size: 20px;
    padding: 10px 20px 10px 20px;
    font-weight: bold;
    color: black;
  }

  .shearAndMomentDiagramFooter {
    position: relative;
    top: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    font-size: 10px;
    padding: 0px 0px 0 0px;
    font-weight: bold;
    color: rgb(200, 200, 200);
  }

  .shearAndMomentDiagramExport {
    position: absolute;
    right: 10px;
    top: 80px;
  }

  .exportIcon {
    font-size: 30px;
    color: rgb(60, 140, 190);
    padding: 0 10px;
    cursor: pointer;
  }

  .dataPoint {
    height: 8px;
    width: 8px;
    border-radius: 8px;
    background-color: red;
    z-index: 150;
    position: absolute;
  }

  .dataPoint .tooltiptext {
    visibility: hidden;
    white-space: nowrap;
    margin: 0;
    font-size: 12px;
    color: black;
    text-align: center;
    padding: 0px 2px;
    padding-bottom: 4px;
    position: absolute;
    left: 5px;
    top: 5px;
    z-index: 40;
  }

  .dataPoint:hover .tooltiptext {
    visibility: visible;
  }
}
