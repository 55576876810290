.modalBox {
  color: black;
  background-color: rgb(240, 240, 240);
  border-radius: 5px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.4);
}

.blurBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  backdrop-filter: blur(10px);
  color: white;
}

.modalBanner {
  background-color: rgb(60, 140, 190);
  border-bottom: 1px solid rgb(100, 100, 100);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 40px;
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 0 10px;
  margin: 0 -30px;
}

.modalBody {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.icon {
  font-size: 25px;
  color: white;
  padding: 0 10px;
  cursor: pointer;
}

.icon:hover {
  color: white;
}

/* ---Input Form--- */

.formTitle {
  padding: 20px;
  font-size: 20px;
}

.formButton {
  background-color: rgb(60, 140, 190);
  border: none;
  height: 40px;
  width: 80px;
  margin: 20px;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0;
}

.formButton:hover {
  background-color: rgb(130, 180, 208);
}

.formButtonInactive {
  background-color: #ccc;
  border: none;
  height: 40px;
  width: 80px;
  margin: 20px;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  pointer-events: none;
  padding: 0;
}

.inputFormDropdownGrid {
  display: grid;
  grid-template-columns: 100px 200px;
  align-items: center;
}

.inputFormGrid {
  display: grid;
  grid-template-columns: 100px 200px;
  align-items: center;
}

.formInputLabel {
  text-align: right;
  font-size: 16px;
  margin-right: 20px;
}

.formInputboxContainer {
  position: relative;
}

.formInputUnit {
  position: absolute;
  top: 4px;
  right: 0px;
  margin: none;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
  color: rgb(100, 100, 100);
}

.dropdown {
  font-size: 15px;
  border: 1px solid rgb(118, 118, 118);
  border-radius: 2px;
  padding: 3px;
  margin: 0 0 0 0;
}

option {
  font-size: 15px;
}

.inputBox {
  width: 193px;
  font-size: 15px;
  padding: 3px 0 3px 3px;
  margin: 0 0 0 0;
  border: 1px solid rgb(118, 118, 118);
  border-radius: 2px;
}

.error {
  color: rgb(255, 100, 100);
  margin: none;
  width: 200px;
  font-size: 15px;
}

.errorSpacer {
  height: 25px;
  margin: 0;
  padding: 0;
}

/* ---Terms--- */

.termsWindow {
  width: 500px;
  height: 510px;
  overflow: scroll;
  overflow-x: hidden;
  background-color: rgb(240, 240, 240);
  color: black;
  padding: 10px 40px;
  border: 1px solid black;
}

.termsModalBody {
  /* background-color: white; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.termsModalBox {
  color: black;
  background-color: rgb(240, 240, 240);
  /* height: 320px; */
  border-radius: 5px;
  padding-bottom: 10px;
  /* width: 400px; */
  padding-left: 30px;
  padding-right: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.4);
}

/* ---PreCalculation--- */

.preCalculationErrorTextBox {
  height: 100px;
  width: 360px;
  margin-bottom: 30px;
}

.preCalculationErrorIcon {
  font-size: 50px;
  color: rgb(255, 100, 100);
  padding: 30px 0px;
}

.preCalculationErrorMsg {
  color: rgb(255, 100, 100);
  margin: none;
  font-size: 15px;
  text-align: center;
}

.preCalculationErrorMainMsg {
  color: rgb(255, 100, 100);
  margin: 20px 0;
  font-size: 20px;
  text-align: center;
}

/* ---Tutorial--- */

.tutorialModalBox {
  color: black;
  background-color: rgb(240, 240, 240);
  height: 640px;
  border-radius: 5px;
  /* width: 400px; */
  padding-left: 30px;
  padding-right: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.4);
  /* padding-bottom: 40px; */
}

.tutorialModalBody {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.tutorialNote {
  margin-bottom: 20px;
  font-size: 16px;
}

.tutorial {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 400px;
  padding-bottom: 20px;
}

.tutorialVideo {
  height: 450px;
}

/* ---Beam Selection Modal--- */

.beamOptionContainer {
  display: flex;
  padding: 25px 0;
}

.beamOption {
  background-color: rgb(60, 140, 190);
  width: 120px;
  height: 120px;
  margin: 0 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  color: white;
}

.beamOption:hover {
  background-color: rgb(130, 180, 208);
}

.beamOptionClicked {
  background-color: rgb(157, 200, 226);
  width: 120px;
  height: 120px;
  margin: 0 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  color: white;
}

.beamIcon {
  margin-top: 15px;
  height: 20px;
  object-fit: cover;
}

.beamIconLabel {
  margin: 20px 0 0 0;
  font-size: 16px;
}

@media screen and (max-height: 450px) {
}

@media screen and (max-width: 500px) {
  .modalBox {
    color: black;
    background-color: rgb(240, 240, 240);
    border-radius: 5px;
    padding-bottom: 5vw;
    padding-left: 5vw;
    padding-right: 5vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.4);
  }

  .modalBanner {
    background-color: rgb(60, 140, 190);
    border-bottom: 1px solid rgb(100, 100, 100);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 30px;
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 0;
    margin: 0 -5vw;
  }

  .modalBody {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .icon {
    font-size: 25px;
    color: white;
    padding: 0 10px;
    cursor: pointer;
  }

  .icon:hover {
    color: white;
  }

  /* ---Input Form--- */

  .formTitle {
    padding: 10px;
    font-size: 20px;
  }

  .formButton {
    background-color: rgb(60, 140, 190);
    border: none;
    height: 35px;
    width: 70px;
    margin: 5px;
    color: white;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
    padding: 0;
  }

  .formButton:hover {
    background-color: rgb(130, 180, 208);
  }

  .formButtonInactive {
    background-color: #ccc;
    border: none;
    height: 35px;
    width: 70px;
    margin: 5px;
    color: white;
    font-size: 15px;
    border-radius: 5px;
    pointer-events: none;
    padding: 0;
  }

  .inputFormDropdownGrid {
    display: grid;
    grid-template-columns: 80px 175px;
    align-items: center;
  }

  .inputFormGrid {
    display: grid;
    grid-template-columns: 80px 175px;
    align-items: center;
  }

  .formInputLabel {
    text-align: right;
    font-size: 12px;
    margin-right: 15px;
  }

  .formInputboxContainer {
    position: relative;
  }

  .formInputUnit {
    position: absolute;
    top: 4px;
    right: 0px;
    margin: none;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 8px;
    color: rgb(100, 100, 100);
  }

  .dropdown {
    font-size: 12px;
    border: 1px solid rgb(118, 118, 118);
    border-radius: 2px;
    padding: 3px;
    margin: 0 0 0 0;
  }

  option {
    font-size: 12px;
  }

  .inputBox {
    width: 170px;
    font-size: 12px;
    padding: 3px 0 3px 3px;
    margin: 0 0 0 0;
    border: 1px solid rgb(118, 118, 118);
    border-radius: 2px;
  }

  .error {
    color: rgb(255, 100, 100);
    margin: none;
    width: 200px;
    font-size: 12px;
  }

  .errorSpacer {
    height: 20px;
    margin: 0;
    padding: 0;
  }

  /* ---PreCalculation--- */

  .preCalculationErrorTextBox {
    height: auto;
    width: 255px;
    margin-bottom: 0px;
  }

  .preCalculationErrorIcon {
    font-size: 50px;
    color: rgb(255, 100, 100);
    padding: 30px 0px;
  }

  .preCalculationErrorMsg {
    color: rgb(255, 100, 100);
    margin: none;
    font-size: 12px;
    text-align: center;
  }

  .preCalculationErrorMainMsg {
    color: rgb(255, 100, 100);
    margin: 20px 0;
    font-size: 15px;
    text-align: center;
  }

  /* ---Beam Selection Modal--- */

  .beamOptionContainer {
    display: flex;
    padding: 0 0px;
  }

  .beamOption {
    background-color: rgb(60, 140, 190);
    width: 90px;
    height: 90px;
    margin: 5vw 2vw;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    color: white;
  }

  .beamOption:hover {
    background-color: rgb(130, 180, 208);
  }

  .beamOptionClicked {
    background-color: rgb(157, 200, 226);
    width: 90px;
    height: 90px;
    margin: 5vw 2vw;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    color: white;
  }

  .beamIcon {
    margin-top: 15px;
    height: 15px;
    object-fit: cover;
  }

  .beamIconLabel {
    margin: 20px 0 0 0;
    font-size: 12px;
  }
}
